.contact {

  .medium-banner {
    background-image: url(../img/team-background.png);
  }

  .acf-fields>.acf-field {
    padding: 0;
    border-top: #EEEEEE solid 0px;
  }

  .forms {
    position: relative;

    .block-inner {
      display: flex;
    }

    input, textarea, select {
      border: none;
      background: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      border-bottom: 1px solid #0068B3;
      border-radius: 0;
      resize: none;
      font-family: "Montserrat-Regular";
      font-size: 14px;
      line-height: 18px;
    }

    select {
      cursor: pointer;
    }

    .af-field {
      padding: 0px 20px;
      box-sizing: border-box;
      margin-bottom: 40px;
    }

    .af-label {
      margin-bottom: 5px;
    }

    .acf-error-message {
      font-family: "Montserrat-Regular";
    }

    .af-success {
      font-size: 14px;
      line-height: 18px;
      font-family: "Montserrat-Regular";
      padding: 0px 20px;
    }

    .acf-notice.-error.acf-error-message.-dismiss {
      margin-right: 20px;
      margin-left: 20px;
      box-sizing: border-box;
    }

    .af-field-sujet  {

      label {
        // display: none;
      }
    }

    select, input {
      height: 35px;
    }

    .af-submit {
      font-size: 14px;
      line-height: 18px;
      background-color: #0068B3;
      display: inline-block;
      float: right;
      cursor: pointer;
      padding: 0px;
      margin-right: 20px;

      @include tablet-landscape {
        margin-right: 0px;
      }

      button {
        border: none;
        font-family: "Montserrat-Regular";
        font-size: 14px;
        line-height: 18px;
        background: transparent;
        color: white;
        cursor: pointer;
        padding: 15px 12px;
      }
    }

    label {
      font-size: 14px;
      line-height: 18px;
      font-family: "Montserrat-Regular";
    }

    .af-field-type-text, .af-field-type-select, .af-field-type-email {
      display: inline-block;
      width: 50%;
      vertical-align: top;
    }

    .af-field-type-select {
      &:before {
        position: absolute;
        right: 25px;
        top: 35px;
        content:"";
        background-image: url("../img/menu/fleche/Black.png");
        height: 12px;
        width: 22px;
        transform: rotate(180deg) scale(0.8);
      }
    }

    .acf-spinner {
      display: none !important;
    }

    select::-ms-expand {
      display: none;
    }

    .background {
      background-color: #0068B3;
      position: absolute;
      height: 100%;
      width: 30%;
      display: none;

      @include tablet-landscape {
        display: block;
      }
    }

    .col-50 {
      padding: 60px 0px;
      position: relative;

      &.left {
        background-color: #0068B3;
        overflow: hidden;
        width: 30%;
        display: none;

        @include tablet-landscape {
          display: block;
        }

        h2 {
          color: white;
        }

        a {
          color: white;
          font-family: "Montserrat-Regular";
          font-size: 14px;
          line-height: 18px;
          display: block;
          text-decoration: none;

          &.adress {
            margin-top: 30px;
            display: inline-block;
          }

          &.tel {
            margin-top: 20px;
            display: inline-block;
          }

          &.mail {
            margin-top: 20px;
            display: inline-block;
          }

          &.linkedin {
            margin-top: 30px;
            display: inline-block;
          }

        }

        .circle {
          height: 300px;
          width: 300px;
          border-radius: 50%;
          border: 50px solid #00B7F4;
          position: absolute;
          right: -150px;
          bottom: -160px;
        }
      }

      &.right {
        box-sizing: border-box;
        width: 100%;

        @include tablet-landscape {
          width: 70%;
          padding-left: 80px;
        }

        h2 {
          padding: 0px 20px;
          box-sizing: border-box;
          margin-bottom: 30px;
        }
      }

    }

  }
}
