.members.become-member-page {

  .medium-banner {
    background-image: url("../img/team-background.png");
  }

  .summary {
    .image {
      position: relative;
      overflow: hidden;
      min-height: 350px;
    }

    p {
      text-align: center;
      font-family: "Montserrat-Regular";
      max-width: 713px;
      margin: 0 auto;
      margin-top: 10px;
      font-size: 14px;
      line-height: 18px;
      width: 100%;

      @include tablet-landscape {
        width: 80%;
      }

    }

    .circle.blue-medium {
      height: 270px;
      width: 270px;
      left: 30px;
      top: 0px;
      position: absolute;
      z-index: 3;
      border: 30px solid #0068B3;
      border-radius: 50%;
      background-size: cover;
      background-position: center;
    }

    .circle.grey {
      height: 140px;
      width: 140px;
      left: 264px;
      top: 150px;
      position: absolute;
      z-index: 2;
      border: 30px solid #D0D4D4;
      border-radius: 50%;
    }

    .circle.blue-light {
      height: 200px;
      width: 200px;
      left: 294px;
      top: 0px;
      position: absolute;
      z-index: 1;
      border: 40px solid #00B7EB;
      border-radius: 50%;
    }

  }

  .stat-line {
    .stat {
      width: calc(100%);
      padding: 0px 20px;
      box-sizing: border-box;

      @include tablet-landscape {
        width: calc(100% / 3);
      }

    }
  }

  .image {
    text-align: center;
    display: none;

    @include tablet-landscape {
      display: inline-block;
    }

    img {
      width: 100%;
      max-width: 540px;
    }
  }

  h2 {
    text-align: center;
  }

  .list {
    line-height: 16px;
    margin-top: 40px;

    p {
      text-align: center;
      width: 100%;
      max-width: none;
      @include tablet-landscape {
        text-align: left;
      }
      font-family: "Montserrat-Regular";
      font-size: 14px;
      margin: 0;
    }

    ol {
      margin: 0;
      padding-left: 18px;
    }

    ol li {
      font-family: "Montserrat-Regular";
      font-size: 14px;
      list-style-type: none;
      margin: 0;
      padding: 0;
      text-align: center;

      @include tablet-landscape {
        list-style-type: inherit;
        text-align: left;
      }

      span {
        color: #00B7EB;
      }
    }
  }

  .selection-process {
    padding: 0px 0px 40px;

    @include tablet-landscape {
      padding: 0px 0px 80px;
    }

    h2 {
      text-align: center;
    }

    p {
      text-align: center;
      font-family: "Montserrat-Regular";
      max-width: 713px;
      margin: 0 auto;
      margin-top: 10px;
      font-size: 14px;
      line-height: 18px;
      width: 100%;

      @include tablet-landscape {
        width: 80%;
      }

    }
  }

}
