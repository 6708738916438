@font-face {
	font-family: 'Montserrat-Regular';
	src: url('../fonts/Montserrat-Regular.eot');
	src: local('☺'), url('../fonts/Montserrat-Regular.woff') format('woff'),
  url('../fonts/Montserrat-Regular.ttf') format('truetype'),
  url('../fonts/Montserrat-Regular.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat-Light';
	src: url('../fonts/Montserrat-Light.eot');
	src: local('☺'), url('../fonts/Montserrat-Light.woff') format('woff'),
  url('../fonts/Montserrat-Light.ttf') format('truetype'),
  url('../fonts/Montserrat-Light.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto-Thin';
	src: url('../fonts/Roboto-Thin.eot');
	src: local('☺'), url('../fonts/Roboto-Thin.woff') format('woff'),
  url('../fonts/Roboto-Thin.ttf') format('truetype'),
  url('../fonts/Roboto-Thin.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Karla-Regular';
	src: url('../fonts/Karla-Regular.eot');
	src: local('☺'), url('../fonts/Karla-Regular.woff') format('woff'),
  url('../fonts/Karla-Regular.ttf') format('truetype'),
  url('../fonts/Karla-Regular.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Karla-Bold';
	src: url('../fonts/Karla-Bold.eot');
	src: local('☺'), url('../fonts/Karla-Bold.woff') format('woff'),
  url('../fonts/Karla-Bold.ttf') format('truetype'),
  url('../fonts/Karla-Bold.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
