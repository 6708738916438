.members.members {
  position: relative;

  .summary {
    text-align: center;

    h2, p {
      opacity: 0;
    }

    .btn {
      margin-top: 20px;
      margin-left: 20px;
      margin-right: 20px;
      opacity: 0;
      @include tablet-landscape {
        margin-top: 60px;
      }

    }
  }

  .map-banner {
    position: relative;
    height: 430px;
    width: 100%;
    overflow: hidden;

    @include desktop {
      height: 800px;
    }

    .map-wrapper {
      position: relative;
      height: 100%;
      width: 100%;
    }
  }

  .sub-legend {
    background: #002B4A;
    padding: 40px 0px;
    color: white;

    h2 {
      font-family: "Montserrat-Light";
      font-size: 22px;
    }

    p {
      font-family: "Montserrat-Light";
      font-size: 14px;
      line-height: 22px;
      margin-top: 5px;
      text-align: center;
    }

    &.second {
      padding: 20px 0px;

      p {
        text-align: left;
      }
    }

  }

  .medium-banner {
    background-image: url(../img/team-background.png);
  }

  .icons {
    h2 {
      margin-bottom: 20px;
    }
  }

  .charts {
    font-size: 0;
    text-align: center;
    padding: 0px 0px 30px;

    @include tablet-landscape {
      padding: 60px 0px;
    }

    .col-chart {
      box-sizing: border-box;
      padding: 0px 20px;

      .col-25 {
        width: calc(100% / 2);

        @include tablet-landscape {
          width: calc(100% / 4);
        }
      }
    }

    canvas {
      margin-top: 20px;
      width: 100%;
    }

    .legend {
      padding: 0px 5px;
      box-sizing: border-box;
      margin-top: 30px;
      display: block;
    }

    .legend1 {
      font-family: "Karla-Bold";
      font-size: 16px;
      text-align: center;
      text-transform: uppercase;
    }

    .legend2 {
      text-align: center;
      font-family: "Karla-Bold";
      font-size: 16px;
      text-transform: uppercase;
    }

    .legend3 {
      font-family: "Karla-Bold";
      text-align: center;
      font-size: 18px;
    }

    .legend4 {
      font-family: "Karla-Bold";
      text-align: center;
      font-size: 18px;
    }

    .legend5 {
      font-family: "Karla-Bold";
      text-align: center;
      font-size: 18px;
    }

    .legend6 {
      font-family: "Karla-Bold";
      text-align: center;
      font-size: 18px;
    }

    span.small {
      font-size: 10px;
      display: block;
    }
  }

  h2, h3 {
    text-align: center;
  }

  h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    display: inline-block;

    @include tablet-landscape {
      margin-top: 70px;
      margin-bottom: 20px;
    }
  }



}
