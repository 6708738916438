.intervention {
  position: relative;
  background-color: white;
  padding: 60px 0px;

  h2 {
    font-family: "Karla-Bold";
    text-align: center;

    @include smobile {
      font-size: 19px;
      line-height: 26px;
    }
  }

  .description {
    font-family: "Montserrat-Regular";
    text-align: center;
    max-width: 680px;
    margin: 20px auto 0px;
    font-size: 14px;
    line-height: 18px;
  }

  .intervention-icons {
    position: relative;
    width: 100%;
    margin-top: 40px;
    opacity: 1;
    transition: 0.3s opacity ease;
    text-align: center;

    .btn {
      margin-top: 20px;
    }

    &.open .intervention-icon {

      &.open .intervention-wrapper{
        transition: 0.3s opacity ease;
        opacity: 1;
        background-color: #F0F2F2;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
      
      .intervention-wrapper {
        opacity: 0.3;
        transition: 0.3s opacity ease;

        &:hover {
          opacity: 1;
          transition: 0.3s opacity ease;
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
        }
      }
    }

    .intervention-icon {
      display: inline-block;
      width: calc(100% / 3);
      vertical-align: top;

      @media screen and (max-width: 430px) {
        width: calc(100%);
      }

      .intervention-wrapper {
        display: inline-block;
        padding: 30px 0px;
        box-sizing: border-box;
        border-radius: 10px;
        text-transform: uppercase;
        transition: 0.3s background ease;
        width: 80%;
        max-width: 250px;

        &:hover {
          background-color: #F0F2F2;
          transition: 0.3s background ease;
        }

        .icon {
          height: 75px;
          margin-bottom: 16px;
        }

        .title {
          margin: 0 auto;
          font-size: 17px;
          line-height: 22px;
          font-family: "Karla-Bold";
          color: #0068B3;
          text-transform: uppercase;
        }

        .load-more {
          font-size: 14px;
          color: #0068b3;
          font-family: "Karla-Regular";
          margin-top: 30px;
          text-transform: uppercase;
        }

      }
    }

    .content {
      position: relative;
      display: block;
      background-color: #F0F2F2;
      box-sizing: border-box;
      border-radius: 5px;
      max-height: 0px;
      overflow: hidden;
      transition: 0.3s max-height $ease-out-expo;

      &.open {
        max-height: 800px;
      }

      .cross {
        position: absolute;
        height: 22px;
        width: 22px;
        right: 20px;
        top: 20px;
        cursor: pointer;
        z-index: 2;

        .first {
          position: absolute;
          height: 25px;
          width: 2px;
          background-color: #0068B3;
          transform: translate3d(-50%, -50%, 0) rotate(45deg);
          top: 50%;
          left: 50%;
        }

        .second {
          position: absolute;
          height: 25px;
          width: 2px;
          background-color: #0068B3;
          transform: translate3d(-50%, -50%, 0) rotate(-45deg);
          top: 50%;
          left: 50%;
        }
      }

      .content-inner {
        padding: 45px 50px 30px 50px;
        text-align: left;



        h4 {
          font-family: "Montserrat-Regular";
          font-size: 16px;
          margin-top: 10px;
        }

        p, li {
          font-family: "Montserrat-Regular";
          // font-size: 14px;
          line-height: 23px;
        }

        li {
          list-style: initial;
          margin-left: 20px;
        }

        blockquote p {
          font-family: "Karla-Bold";
          transform: skew(-10deg, 0deg);
          font-size: 20px;
          line-height: 27px;
        }
      }

    }

  }

  // .accordions {
  //   margin-top: 40px;
  //
  //   .accordion {
  //     .top {
  //       font-size: 0;
  //       border-top: 1px solid #D8D8D8;
  //       padding: 15px 0px;
  //
  //       @include tablet-portrait {
  //         padding: 10px 0px;
  //       }
  //
  //       .icon {
  //         display: inline-block;
  //         vertical-align: middle;
  //         width: 60px;
  //
  //         img {
  //           width: 100%;
  //         }
  //
  //       }
  //
  //       .title {
  //         display: inline-block;
  //         vertical-align: middle;
  //         width: calc(100% - 70px - 30px);
  //         padding-right: 10px;
  //         box-sizing: border-box;
  //         padding-left: 10px;
  //
  //         @include tablet-portrait {
  //           width: calc(100% - 100px - 40px);
  //           padding-right: 30px;
  //           padding-left: 50px;
  //         }
  //
  //         h3 {
  //           font-family: "Montserrat-Light";
  //           box-sizing: border-box;
  //           padding: 20px 0px;
  //         }
  //       }
  //
  //     }
  //
      .bottom {

      }
  //
  //
  //
  //   }
  // }
}
