.board-of-director {
  .medium-banner {
    background-image: url(../img/team-background.png);
  }

  .the-board {
    padding: 40px 0px;

    @include tablet-landscape {
      padding: 80px 0px;
    }

  }

  .people-status {
    min-height: auto;
  }

  .people .people-socials {
    margin-top: 10px;
  }

}


.the-board, .the-team {
  .col-25 {
    @include tablet-portrait {
      width: calc(100% / 2);
    }

    @include mobile {
      width: calc(100% / 2);
    }

    @include tablet-landscape {
      width: calc(100% / 4);
    }

    @include desktop {
      width: calc(100% / 4);
    }

    .people .people-face {

      @include tablet-portrait {
        height: 100px;
        width: 100px;
        border: 5px solid #d8d8d8;

        &.blue {
          border: 25px solid #0068B3;
        }
      }

      @include mobile {
        height: 100px;
        width: 100px;
        border: 5px solid #d8d8d8;

        &.blue {
          border: 25px solid #0068B3;
        }
      }

      @include tablet-landscape {
        height: 160px;
        width: 160px;
        overflow: hidden;
        display: inline-block;
        border: 25px solid #d8d8d8;

        &.blue {
          border: 25px solid #0068B3;
        }
      }

      @include desktop {
        height: 160px;
        width: 160px;
        overflow: hidden;
        display: inline-block;
        border: 25px solid #d8d8d8;

        &.blue {
          border: 25px solid #0068B3;
        }
      }

    }
  }

  .col {
    .people .people-face {
      @include tablet-portrait {
        height: 100px;
        width: 100px;
        border: 5px solid #d8d8d8;

        &.blue {
          border: 5px solid #0068B3;
        }
      }

      @include mobile {
        height: 100px;
        width: 100px;
        border: 5px solid #d8d8d8;

        &.blue {
          border: 5px solid #0068B3;
        }
      }

      @include tablet-landscape {
        height: 160px;
        width: 160px;
        overflow: hidden;
        display: inline-block;
        border: 25px solid #d8d8d8;

        &.blue {
          border: 25px solid #0068B3;
        }
      }

      @include desktop {
        height: 160px;
        width: 160px;
        overflow: hidden;
        display: inline-block;
        border: 25px solid #d8d8d8;

        &.blue {
          border: 25px solid #0068B3;
        }
      }

    }
  }
}
