.about.team {
  position: relative;

  .medium-banner {
    background-image: url(../img/team-background.png);
  }

  .people-status {
    min-height: 35px;
    box-sizing: border-box;
    padding: 0px 15px;
  }

  .people .people-socials {
    margin-top: 10px;
  }

  .direction-quote {
    position: relative;
    padding: 30px 0px 40px;
    background-color: #0068B3;
    overflow: hidden;

    @include tablet-landscape {
      padding: 60px 0px 40px;
    }

    .col-50 {
      position: relative;
      z-index: 5;
      text-align: center;

      @include tablet-landscape {
        text-align: left;
      }
    }

    .image {
      box-sizing: border-box;
      text-align: center;
      margin-bottom: 20px;

      @include tablet-landscape {
        text-align: right;
        padding-right: 175px;
        margin-bottom: 0px;
      }

      img {
        position: relative;
        z-index: 3;
        width: 200px;
        transform: translate3d(-40px, 0px, 0px);
        opacity: 0;
        
        @include tablet-landscape {
          width: auto;
        }
      }
    }

    h2 {
      color: white;
      margin-bottom: 20px;
      transform: translate3d(40px, 0px, 0px);
      opacity: 0;
    }

    p {
      transform: translate3d(40px, 0px, 0px);
      opacity: 0;
    }

    .circle {
      position: absolute;
      top: 0px;
      border: 60px solid;
      border-radius: 50%;

      &.grey {
        border-color: #D0D4D4;
        height: 150px;
        width: 150px;
        top: 50%;
        left: 40px;
        transform: translate3d(0px, -200px, 0px);
        z-index: 1;
      }

      &.blue-light {
        border-color: #00B7EB;
        height: 300px;
        width: 300px;
        left: -150px;
        top: 50%;
        transform: translateY(-10%);
        z-index: 2;
      }

    }

    p {
      color: white;

      &.description {
        font-family: "Montserrat-Regular";
      }

      &.name {
        font-family: "Karla-Bold";
        text-transform: uppercase;
        margin-top: 30px;
        font-size: 16px;
        line-height: 20px;
      }

      &.status {
        font-family: "Montserrat-Regular";
        font-size: 16px;
        line-height: 20px;
      }
    }

  }

  .the-team {
    padding: 30px 0px;
    background-color: white;

    @include tablet-landscape {
      padding: 60px 0px;
    }

    h2 {
      text-align: center;

      &.advisor {
        margin-top: 80px;
      }
    }

    .description {
      font-family: "Montserrat-Regular";
      margin-top: 20px;
      margin-bottom: 40px;
      max-width: 680px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
  }

}
