footer {
  font-size: 0;
  padding: 45px 0px 0px;
  background-color: #F0F2F2;

  .col {
    display: inline-block;
    vertical-align: top;
    text-align: left;
    width: calc(100%);
    text-align: center;

    @include tablet-landscape {
      width: calc(100% / 4);
      margin-bottom: 0px;
      text-align: left;
    }

    &.col1 {
      margin-bottom: 20px;

      @include tablet-landscape {
        margin-bottom: 0px;
      }

    }

    &.col2 {
      p {
        font-family: "Montserrat-Light";
        font-size: 22px;
        text-align: center;

        @include tablet-landscape {
          text-align: left;
        }
      }
    }

    .logo {
      width: 69px;
      height: 69px;
    }

    a {
      display: block;
    }

    .social {
      a {
        display: inline-block;
      }
    }

    ul li, p, a {
      font-family: "Karla-Regular";
      font-size: 12px;
      line-height: 21px;
      margin-bottom: 7px;
      color: #002B4A;
      text-decoration: none;

      a {
        text-decoration: none;
        color: #002B4A;
        box-sizing: border-box;

      }

      &.title a {
        font-family: "Karla-Bold";
        text-transform: uppercase;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 10px;
      }
    }
  }

  .bottom-footer {
    text-align: center;
    font-family: "Karla-Bold";
    padding: 20px 0px;
    font-size: 12px;
    line-height: 21px;
    margin-bottom: 7px;
    color: #002B4A;
    margin-top: 30px;

    a {
      text-decoration: none;
      color: #002B4A;
    }
  }

}
