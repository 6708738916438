.founders-enterprise {
  position: relative;
  padding: 60px 0px;
  font-size: 0;

  h2 {
    text-align: center;
  }

  p {
    font-family: "Montserrat-Regular";
    // font-size: 14px;
    // line-height: 18px;
    text-align: center;
    max-width: 680px;
    margin: 20px auto 0px;
  }

  .item {
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    margin-top: 50px;

    @include mobile {
      width: calc(100% / 2);
    }

    @include smobile {
      width: calc(100%);
    }

    @include tablet-portrait {
      width: calc(100% / 3);
    }

    @include desktop {
      width: calc(100% / 6);
    }

    &.item-25 {

      @include mobile {
        width: calc(100% / 2);
      }

      @include smobile {
        width: calc(100%);
      }

      @include tablet-portrait {
        width: calc(100% / 3);
      }

      @include desktop {
        width: calc(100% / 4);
      }
    }

    img {
      padding: 0px 10px;
      box-sizing: border-box;
    }
  }

}
