.icons {
  position: relative;
  background-color: white;
  padding: 40px 0px;
  font-size: 0;
  text-align: center;

  .btn {
    margin-top: 20px;
  }

  @include tablet-landscape {
    padding: 60px 0px;
  }

  h2 {
    font-family: "Karla-Regular";
    // font-size: 30px;
    // line-height: 36px;
    text-align: center;
  }

  .block-icon {
    position: relative;
    display: inline-block;
    width: 50%;
    text-align: center;
    vertical-align: top;
    margin-bottom: 0px;
    margin-top: 20px;

    &.block-icon-20 {
      width: 50%;

      @include tablet-portrait {
        width: calc(100% / 3);
      }

      @include tablet-landscape {
        width: calc(100% / 4);
      }

      @include desktop {
        width: calc(100% / 6);
      }

    }

    @include tablet-portrait {
      width: calc(100% / 3);
      margin-bottom: 40px;
    }

    p {
      font-family: "Karla-Bold";
      // font-size: 16px;
      // line-height: 21px;
      color: #0068B3;
      text-transform: uppercase;
    }
  }

}
