*, img {
  user-select: none;
  -webkit-user-drag: none;
  user-drag: none;
  -webkit-touch-callout: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul, li {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  display: inline-block;
}

.oxo-view {
  position: relative;
}

h1, h2, h3, h4, p {
  margin: 0;
  padding: 0;
}

.block-inner {
  position: relative;
  padding: 0px 20px;
  max-width: 1280px;
  margin: 0 auto;
  box-sizing: border-box;
  font-size: 0px;

  @include tablet-landscape {
    padding: 0px 40px;
  }
}


// col
.col-50 {
  width: 100%;
  display: inline-block;
  vertical-align: top;

  @include tablet-landscape {
    width: 50%;
  }
}

.col-33 {
  width: calc(100%);
  display: inline-block;
  vertical-align: top;
  text-align: center;

  @include tablet-landscape {
    width: calc(100% / 3);
  }
}

.col-25 {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  width: calc(100%);

  @include tablet-landscape {
    width: 25%;
  }


}
// end col

.medium-banner {
  position: relative;
  height: 455px;
  width: 100%;
  background-size: cover;
  background-position: center;

  h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    color: white;
    width: 100%;
  }
}

h1 {
  font-family: "Karla-Bold";
  font-size: 32px;
  line-height: 34px;
  text-align: center;

  @include tablet-portrait {
    font-size: 45px;
    line-height: 47px;
  }

  @include tablet-landscape {
    font-size: 70px;
    line-height: 82px;
  }
}

h2 {
  font-family: "Karla-Regular";
  font-size: 26px;
  line-height: 28px;

  @include tablet-landscape {
    font-size: 30px;
    line-height: 36px;
  }
}

h3 {
  font-family: "Montserrat-Light";
  font-size: 14px;
  line-height: 21px;

  @include tablet-landscape {
    font-size: 22px;
    line-height: 29px;
  }
}

p {
  font-size: 12px;
  line-height: 16px;

  @include tablet-landscape {
    font-size: 14px;
    line-height: 18px;
  }
}
