.our-offer {
  position: relative;

  .medium-banner {
    background-image: url(../img/team-background.png);
  }

  .service-offer {
    padding: 30px 0px 0px 0px;

      h2 {
        text-align: center;

        @include tablet-landscape {
          text-align: left;
        }

      }

      p {
        text-align: center;

        @include tablet-landscape {
          text-align: left;
        }
      }


    @include tablet-landscape {
      padding: 60px 0px;
    }

    .col-50 {
      vertical-align: middle;
    }

    p {
      font-family: "Montserrat-Regular";
      font-size: 14px;
      line-height: 18px;
      margin-top: 20px;
    }

    .image {
      text-align: center;

      @include tablet-landscape {
        text-align: right;
      }
      
      img {
        width: 100%;
        max-width: 496px;
        padding-left: 0px;
        box-sizing: border-box;
        margin-top: 40px;

        @include tablet-landscape {
          padding-left: 20px;
        }

      }
    }
  }

  .the-team {
    position: relative;
    padding: 60px 0px;

    h2 {
      text-align: center;
    }

    p {
      text-align: center;
      font-family: "Montserrat-Regular";
      font-size: 14px;
      line-height: 18px;
      margin-top: 20px;
      max-width: 690px;
      margin: 20px auto 0;
      margin-bottom: 40px;
    }
  }

}
