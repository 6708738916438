header {
  position: fixed;
  z-index: 10;
  height: 60px;
  width: 100%;
  top: 30px;
  box-sizing: border-box;
  max-width: 1280px;
  transform: translateX(-50%);
  left: 50%;
  padding: 0 20px;
  z-index: 9997;

  @include tablet-landscape {
    padding: 0 40px;
  }

  .burger {
    position: absolute;
    height: 40px;
    width: 29px;
    right: 25px;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    cursor: pointer;

    @include tablet-landscape {
      display: none;
    }

    .t {
      height: 2px;
      width: 100%;
      position: absolute;
      top: 12px;
      left: 0;
      background: #0068B3;
    }

    .m {
      height: 2px;
      width: 100%;
      position: absolute;
      top: 20px;
      left: 0;
      background: #0068B3;
    }

    .b {
      height: 2px;
      width: 100%;
      position: absolute;
      top: 29px;
      left: 0;
      background: #0068B3;
    }

  }

  .header-inner {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 0 15px;
    box-sizing: border-box;
    box-shadow: 0px 3px 22px -11px rgba(0,0,0,0.5);

    .logo {
      position: relative;
      height: 100%;
      width: 50px;

      img {
        position: absolute;
        height: 50px;
        width: 50px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .menu-main-container, .menu-main-en-container {
      // height: 100%;
      position: absolute;
      top: 0px;
      left: 95px;
      height: 100%;
      font-size: 0;
      width: calc(100% - 95px);

      .nav {
        height: 100%;
        width: 100%;
        display: none;

        @include tablet-landscape {
          display: block;
        }

        li {

          &:last-child {
            float: right;
            padding-right: 10px;
          }


          @include desktop {
            &:hover {
              background-color: #0068B3;

              > a {
                color: white;
              }
            }
          }
        }

        .current_page_parent {
          background-color: #0068B3;

          > a {
            color: white;
          }
        }

        > li {
          display: inline-block;
          height: 100%;
          vertical-align: top;
          text-transform: uppercase;

          li {

            &.current-menu-item {
              background-color: #0068B3;

              > a {
                color: white;
              }

            }
          }

          &.current-menu-item {
            background-color: #0068B3;

            > a {color: white;}
          }

          &.no-click {
            > a {
              pointer-events: none;
            }
          }

          &:hover {
            .sub-menu {
              display: block;
            }
          }

          a {
            font-family: "Karla-Regular";
            text-decoration: none;
            color: #002B4A;
            padding: 0 15px;
            height: 100%;
            line-height: 60px;
            font-size: 12px;
          }

          .sub-menu {
            position: absolute;
            background: white;
            display: none;
          }

        }
      }
    }

    .menu-member-access-container {
      position: absolute;
      height: 100%;
      right: 67px;
      top: 0px;

      @include tablet-landscape {
        right: 0;
      }

      .nav {
        height: 100%;

        li {
          height: 100%;
          position: relative;

          &:hover {
            @include tablet-landscape {
              &:before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0px;
                height: 4px;
                background-color: white;
                width: 100%;
              }
            }
          }

          a {
            font-size: 12px;
            font-family: "Karla-Regular";
            color: white;
            text-decoration: none;
            text-transform: uppercase;
            background-color: #0068B3;
            padding: 0 15px 0px 30px;
            height: 100%;
            line-height: 60px;

            &:before {
              content: "";
              height: 17px;
              width: 12px;
              background-image: url(../img/cadena.png);
              background-size: 12px 17px;
              position: absolute;
              top: 20px;
              left: 10px;
            }

          }

        }

      }

    }

  }
}

.black-layer {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 9998;
  opacity: 0;
  transition: 0.6s opacity ease;
  transform: translate3d(-100%, 0px, 0px);

  &.open {
    opacity: 1;
    transition: 0.6s opacity ease;
    transform: translate3d(0px, 0px, 0px);
  }
}

.menu-mobile {
  position: fixed;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 240px;
  background: white;
  text-align: center;
  z-index: 9999;
  overflow: auto;
  transform: translate3d(100%, 0px, 0px);
  transition: 0.6s transform $ease-in-expo;

  .wpml-ls-item {
    > a {
      display: none;
    }

    .sub-menu {
      display: block !important;
      margin-top: 5px;
      a  {
        display: block;

        span {
          font-family: "Karla-Bold" !important;
        }
      }
    }
  }

  li {
    position: relative;
    line-height: 40px;
  }

  &.open {
    transform: translate3d(0px, 0px, 0px);
    transition: 0.6s transform $ease-out-expo;

    @include tablet-landscape {
      display: none;
    }
  }

  @include tablet-landscape {
    display: none;
  }

  .cross {
    position: absolute;
    height: 22px;
    width: 22px;
    right: 20px;
    top: 20px;
    cursor: pointer;
    z-index: 2;

    .first {
      position: absolute;
      height: 25px;
      width: 2px;
      background-color: #0068B3;
      transform: translate3d(-50%, -50%, 0) rotate(45deg);
      top: 50%;
      left: 50%;
    }

    .second {
      position: absolute;
      height: 25px;
      width: 2px;
      background-color: #0068B3;
      transform: translate3d(-50%, -50%, 0) rotate(-45deg);
      top: 50%;
      left: 50%;
    }
  }

  .menu-main-container, .menu-main-en-container {
    position: relative;
    line-height: 28px;
    font-size: 17px;
    width: 100%;
    text-align: right;

    .nav-mobile {
      display: inline-block;
      margin-top: 80px;
      margin-right: 50px;

      > li {

        &.no-click {
          > a {
            pointer-events: none;
          }

          &.open {
            .sub-menu {
              display: block;
            }
          }
        }

        > a {
          text-decoration: none;
          color: #0068B3;
          font-family: "Karla-Bold";
          font-size: 16px;
          line-height: 18px;
        }
      }
    }

    .sub-menu {
      display: none;

      a {
        text-decoration: none;
        color: #0068B3;
        font-family: "Karla-Regular";
        font-size: 14px;
        line-height: 16px;
      }
    }

  }

}
