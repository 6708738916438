.founders {
  position: relative;
  background-color: #0068B3;
  font-size: 0;
  overflow: hidden;
  padding: 40px 0px 40px;

  @include tablet-landscape {
    padding: 60px 0px 80px;
  }

  h2 {
    font-family: "Karla-Bold";
    // font-size: 30px;
    color: white;
    text-align: center;
    margin-bottom: 37px;
  }

  .founder {
    position: relative;
    width: 50%;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    vertical-align: top;

    &:nth-child(2) {
      margin-bottom: 20px;

      @include tablet-landscape {
        margin-bottom: 0px;
      }
      
    }

    // @include tablet-landscape {
    //   width: 50%;
    // }

    .founder-circle {
      height: 240px;
      width: 240px;
      border-radius: 50%;
      display: inline-block;
      position: relative;

      @include mobile {
        height: 120px;
        width: 120px;
      }

      &:before {
        content: "";
        position: absolute;
        height: 65px;
        width: 65px;
        background-color: #00B7EB;
        border-radius: 50%;
        z-index: 1;
        background-image: url(../img/quote.png);
        right: -7px;
        top: -15px;
        background-size: 60%;
        background-repeat: no-repeat;
        background-position: center;
      }

      .founder-inner {
        position: absolute;
        background-color: white;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        z-index: 2;
      }

      .founder-face {
        position: absolute;
        top: 50%;
        left: 50%;
        background-position: center;
        transform: translate3d(-50%, -50%, 0);
        background-size: cover;
        height: 200px;
        width: 200px;
        z-index: 3;

        @include mobile {
          height: 100px;
          width: 100px;
        }
      }

    }

    .description {
      font-family: "Montserrat-Regular";
      // font-size: 14px;
      // line-height: 18px;
      color: white;
      margin: 10px auto 0;
      width: 80%;
    }

    .name {
      font-family: "Karla-Bold";
      text-transform: uppercase;
      color: white;
      font-size: 16px;
      line-height: 21px;
      margin-top: 30px;
    }

    ul {
      margin-top: 5px;

      li {
        font-family: "Montserrat-Regular";
        color: white;
        font-size: 12px;
        line-height: 16px;

        @include tablet-landscape {
          font-size: 16px;
          line-height: 20px;
        }

      }
    }

    .endorsement {
      margin-top: 30px;
    }

  }

  .bio-details {
    position: relative;
    background: white;
    transition: 3s max-height $ease-out-expo;
    margin-bottom: -100px;
    display: none;
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.41);
    top: 0px;
    left: 0px;
    position: fixed;
    z-index: 10;
    overflow: auto;
    height: 100%;

    @include tablet-landscape {
      position: relative;
      top: -125px;
      overflow: hidden;
      max-height: 0px;
      z-index: 2;
      height: auto;
    }

    .switch {
      box-sizing: border-box;
      font-family: "Karla-Bold";
      margin-bottom: 30px;
      padding: 0px 20px;
      padding-top: 40px;

      @include tablet-landscape {
        padding: 0px 80px;
        padding-top: 40px;
      }

      > div  {
        font-size: 16px;
        display: block;
        color: #0068B3;
        text-transform: uppercase;
        cursor: pointer;

        @include tablet-landscape {
          display: inline-block;
        }

        &:nth-child(2) {
          margin-left: 0px;
          margin-top: 10px;

          @include tablet-landscape {
            margin-left: 30px;
            margin-top: 0px;
          }
        }
      }
    }

    .cross {
      position: absolute;
      height: 44px;
      width: 44px;
      right: 20px;
      top: 20px;
      cursor: pointer;

      .first {
        position: absolute;
        height: 35px;
        width: 2px;
        background-color: #0068B3;
        transform: translate3d(-50%, -50%, 0) rotate(45deg);
        top: 50%;
        left: 50%;
      }

      .second {
        position: absolute;
        height: 35px;
        width: 2px;
        background-color: #0068B3;
        transform: translate3d(-50%, -50%, 0) rotate(-45deg);
        top: 50%;
        left: 50%;
      }
    }

    &.open {
      max-height: 1000px;
      transition: 3s max-height $ease-out-expo;
      display: block;

      &.t1 {
        .text1 {
          max-height: 1000px;
          transition: 2s max-height $ease-out-expo;
          display: block;
        }

        .name2 {
          color: #D0D4D4;
        }
      }

      &.t2 {
        .text2 {
          max-height: 1000px;
          transition: 2s max-height $ease-out-expo;
          display: block;
        }

        .name1 {
          color: #D0D4D4;
        }
      }

    }

    .text {
      font-family: "Montserrat-Regular";
      font-size: 14px;
      line-height: 18px;
      padding: 0px 20px 60px;
      box-sizing: border-box;
      max-height: 0px;
      transition: 0.9s max-height $ease-out-expo;
      max-height: 0;
      display: none;

      @include tablet-landscape {
        padding: 0px 80px 60px;
      }

    }
  }

  .separator {
    position: absolute;
    height: 420px;
    width: 1px;
    background-color: #D8D8D8;
    top: 60px;
    left: 50%;
    transform: translate3d(-50%, 0, 0px);
    display: none;

    @include tablet-landscape {
      display: block;
    }
  }

  .bio {
    position: relative;
    text-align: center;
    margin-top: 20px;

    .col-50 {
      width: 50%;
    }

    a {
      display: inline-block;
      text-transform: uppercase;
      font-family: "Karla-Bold";
      font-size: 12px;
      line-height: 14px;
      text-decoration: underline;
      color: white;
    }
  }

}
