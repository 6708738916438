.oxo-view.intra-ca {

    .medium-banner {
        overflow: hidden;
        @include mobile {
            height: 185px;
            padding-top: 60px;
        }
        svg {
            z-index: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            transform: translate(-50%, -50%);
            object-fit: cover;
            @include mobile {
                width: auto;
            }
        }
        h1 {
            position: relative;
            z-index: 2;
            opacity: 1;
        }
    }

    .block-inner {
        font-size: 1em;
        font-family: 'Montserrat-Regular';
        display: flex;
        @include tablet {
            flex-direction: column-reverse;
        }

        >.content {
            width: 100%;
            flex-shrink: 1;
        }

        >.dates {
            flex-shrink: 0;
            width: 280px;
            margin-top: 80px;
            margin-left: 10%;
            @include tablet {
                width: 100%;
                margin-left: 0%;
            }
            @include mobile {
                margin-top: 60px;
            }

            .dates_box {
                width: 280px;
                border-radius: 6px;
                border: 2px solid #F2F2F2;
                position: sticky;
                top: 160px;
                left: 0;
                width: 100%;
                @include tablet {
                    width: 100%;
                    display: block; 
                    top:auto;
                }

                h5 {
                    font-family: 'Karla-Bold';
                    border-bottom: 2px solid #F2F2F2;
                    font-size: 20px;
                    margin: 0;
                    padding: 24px 28px;
                    margin-bottom: 10px;
                }

                .date {
                    padding: 10px 28px;
                    display: flex;
                    align-items: center;

                    &:last-of-type {
                        padding-bottom: 32px;
                    }

                    svg {
                        margin-right: 12px;
                        flex-shrink: 0;
                    }

                    h6 {
                        margin: 0;
                        padding: 0;
                        font-family: "Montserrat";
                        font-weight: 600;
                        font-size: 15px;
                    }

                    p {
                        font-family: "Montserrat";
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 500;
                    }
                }
            }
        }
    }

    .section_intro {
        padding-top: 80px;
        padding-bottom: 70px;
        @include mobile {
            padding-top: 60px;
            padding-bottom: 50px;
        }

        .title-top {
            p {
                padding-top: 20px;
                color: #606060;
                font-family: 'Montserrat';
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
            }
        }

        .links_container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-left: -10px;
            margin-right: -10px;
            padding-top: 40px;
            @include mobile {
                padding-top: 30px;
                // flex-direction: column;
                // justify-content: flex-start;
                // align-items: center;
            }

            a, .ghost {
                box-sizing: border-box;
                width: 248px;
                height: 187px;
                border-radius: 6px;
                background: #0068B3;
                padding: 25px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin: 10px;
                text-decoration: none;
                color: #FFF!important;
                transition: all 0.4s;
                @include mobile {
                    margin: 5px;
                    width: calc(50% - 10px);  
                    height: 160px;
                    padding: 15px;
                }

                h6 {
                    font-family: "Montserrat";
                    font-weight: 600;
                    font-size: 18px;
                    font-weight: 700;
                    display: block;
                    margin: 0;
                    padding-bottom: 14px;
                    @include mobile {
                        font-size: 16px;
                    }
                }

                p {
                    text-transform: uppercase;
                    font-family: 'Karla-Bold';
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    @include mobile {
                        font-size: 12px;
                    }

                    svg {
                        margin-left: 7px;
                    }
                }

                &:hover {
                    background-color: #002B4A!important;
                }
            }

            .ghost {
                height: 0;
                background-color: transparent;
                padding: 0px;
                @include mobile {
                    display: none;
                }
            }
        }
    }


    .section_2, .section_3 {
        padding-bottom: 80px;
        @include mobile {
            padding-bottom: 60px;
        }

        .title-top {
            margin-bottom: 30px;
            .surtitre {
                color: #0068B3;
                font-family: 'Montserrat';
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-bottom: 8px;
            }
            p {
                padding-top: 14px;
                color: #606060;
                font-family: 'Montserrat';
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
            }
        }

        .file_row {
            border-radius: 6px;
            background: #CCE1F0;
            padding: 20px 28px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
            width: 100%;
            box-sizing: border-box;
            @include mobile {
                flex-direction: column;
            }

            h5 {
                margin: 0;
                padding: 0;
                color: #000;
                font-family: 'Montserrat';
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                overflow: hidden;
                flex-shrink: 1;
                @include mobile {
                    font-size: 16px;   
                }
            }

            svg {
                margin-left: 15px;
                transition: all 0.3s;
                @include mobile {
                    margin-left: 0px;
                    margin-right: 15px;
                    margin-top: 15px;
                }
            }
            a:hover svg {
                transform: scale(1.15);
            }
        }

        .date_drop {
            border-bottom: 2px solid #0068B3;
            &:first-of-type {
                border-top: 2px solid #0068B3;
            }
            .title {
                padding: 18px 0px;
                color: #000;
                font-family: 'Montserrat';
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                transition: all 0.4s;
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                align-items: center;
                &:hover {
                    color: #0068B3;
                }

                .plus {
                    width: 22px;
                    height: 22px;
                    position: relative;
                    div {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: calc(50% - 1px);
                        background-color: #00B7EB;
                        width: 100%;
                        height: 2px;
                        transition: all 0.4s;
                        &.line1 {
                            transform: rotate(90deg);
                        }
                    }
                }
            }

            .drop_content {
                overflow: hidden;
                height: 0;

                .drop_content-inner {
                    padding-top: 14px;
                    padding-bottom: 24px;
                }
            }

            &.open {
                .title {
                    color: #0068B3;
                    .plus div.line1 {
                        transform: rotate(0deg);
                    }
                }
            }

        }
    }


    .pw-container {
        padding-top: 80px;
        padding-bottom: 80px;
        @include mobile {
            padding-top: 60px;
            padding-bottom: 60px;
        }

        .post-password-form >p:first-of-type {
            font-family: 'Karla-Bold';
            font-size: 32px;
            font-style: normal;
            padding-bottom: 40px;
            padding-right: 40%;
            line-height: 120%;
            @include tablet {
                padding-right: 0%;
            }
        }
        label {
            font-family: 'Montserrat';
            font-weight: 500;
            font-size: 18px;
        }
        input {
            border: none!important;
            background: none!important;
            border-bottom: 1px solid #0068B3!important;
            border-radius: 0!important;
            resize: none!important;
            font-family: "Montserrat-Regular"!important;
            font-size: 14px!important;
            line-height: 18px!important;
            transition: all 0.4s;
            padding-left: 0!important;
            width: 500px;
    
            &:focus {
                color: #0068B3!important;
                box-shadow: none !important;
                outline: none;
                border-bottom: 1px solid #00B7EB!important;
            }
        }

        input[type=submit] {
            display: block;
            border: none;
            font-family: "Montserrat-Regular";
            font-size: 14px;
            line-height: 18px;
            background: transparent;
            color: white;
            cursor: pointer;
            padding: 12px 14px!important;
            background-color: #0068B3!important;
            border-radius: 0;
            transition: all 0.3s!important;
            margin-top: 20px;
            width: auto;
            &:hover {
                background-color: #002B4A!important;
            }
        }
    }
}