.oxo-view.intra-form {

    .medium-banner {
        overflow: hidden;
        @include mobile {
            height: 185px;
            padding-top: 60px;
        }
        svg {
            z-index: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            transform: translate(-50%, -50%);
            object-fit: cover;
            @include mobile {
                width: auto;
            }
        }
        h1 {
            position: relative;
            z-index: 2;
            opacity: 1;
        }
    }

    .block-title {
        padding-top: 100px;
        padding-bottom: 50px;
        @include mobile {
            padding-top: 60px;
        }
    }
    .frm_forms {
        padding-bottom: 60px;
    }
    input, textarea, select {
        border: none!important;
        background: none!important;
        border-bottom: 1px solid #0068B3!important;
        border-radius: 0!important;
        resize: none!important;
        font-family: "Montserrat-Regular"!important;
        font-size: 14px!important;
        line-height: 18px!important;
        transition: all 0.4s;
        padding-left: 0!important;

        &:focus {
            color: #0068B3!important;
            box-shadow: none !important;
            border-bottom: 1px solid #00B7EB!important;
        }

        &[readonly] {
            pointer-events: none!important;
        }
    }
  
    select {
        cursor: pointer;
    }
    label {
        display: block;
        font-weight: bold;
        margin: 0 0 3px;
        padding: 0;
        font-family: "Montserrat-Regular"!important;
        color: #000000!important;
    }
    .not-visible {
        display: none;
    }

    .frm_repeat_buttons {
        margin-top: 10px;
    }
    .frm_add_form_row {
        margin-right: 20px;
    }
    .total_field{
        width: 150px!important;
        margin-top: 30px;
        margin-bottom: 40px!important;
        label {
            font-size: 20px;
        }
    }
    .frm_repeat_sec {
        border: 1px solid rgba(0,0,0,0.1)!important;
        padding: 30px!important;
    }
    .frm_button, .frm_style_formidable-style.with_frm_style .frm_submit button {
        border: none;
        font-family: "Montserrat-Regular";
        font-size: 14px;
        line-height: 18px;
        background: transparent;
        color: white;
        cursor: pointer;
        padding: 12px 14px;
        background-color: #0068B3;
        border-radius: 0;
        transition: all 0.3s!important;
        &:hover {
            background-color: #002B4A!important;
        }

        &.frm_save_draft {
            background-color: #fff; 
            border: 1px solid #0068B3;
            color: #0068B3;
            &:hover {
                background-color: #0068B3!important;
                color: #FFF!important;
            }
        }
    }
    .with_frm_style .frm_inline_box {
        border-radius: 0!important;
        background-color: transparent!important;
        border: none!important;
        font-family: "Montserrat-Regular";
        border: none !important;
        box-shadow: none;
    }

    .frm_verify {
        border-bottom: none!important;
    }

    .grand_total {
        margin-top: 40px;
        border-top: 1px solid rgba(0,0,0,0.1)!important;
        margin-bottom: 30px!important;
        h3 {
            padding-bottom: 20px!important;
        }
        .frm_first {
            width: 150px!important;
        }
    }

    table {
        font-family: 'Montserrat-Regular';
        border-collapse: collapse;
        margin-bottom: 80px;
        @include mobile {
            padding-bottom: 60px;
        }
        thead th {
            border: none;
            border-bottom: 1px solid #002B4A;
            font-family: "Montserrat";
            font-weight: 600;
        }
        p {
            font-size: 1em;
        }
        a {
            color: #000;
            transition: all 0.3s;
            &:hover {
                color: #0068B3;
            }
        }
        th:last-of-type, td:last-of-type {
            width: 80px;
            text-align: right;
        }
        th:nth-of-type(3),td:nth-of-type(3) {
            width: 100px;
        }
        tr {
            @include tablet {
                margin-bottom: 20px;
            }
        }
        td {
            @include tablet {
                width: auto!important;
                text-align: left!important;
            }
            @include mobile {
                padding-left: 20px!important;
                padding-top: 45px!important;
                &::before {
                    top: 10px;
                    width: 100%;
                    font-size: 12px;
                    font-family: "Montserrat";
                    font-weight: 600;
                }
            }

        }
    } 
}

.login_form_container {
    padding-top: 200px;
    padding-bottom: 160px;

    .box {
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;
        padding: 32px 40px;
        border: 1px solid rgba(0,0,0,0.1)!important;
        h2 {
            margin-bottom: 40px;
        }
    }

    input, textarea, select {
        border: none!important;
        background: none!important;
        border-bottom: 1px solid #0068B3!important;
        border-radius: 0!important;
        resize: none!important;
        font-family: "Montserrat-Regular"!important;
        font-size: 14px!important;
        line-height: 18px!important;
        transition: all 0.4s;
        padding-left: 0!important;

        &:focus {
            color: #0068B3!important;
            box-shadow: none !important;
            outline: none;
            border-bottom: 1px solid #00B7EB!important;
        }
    }
  
    select {
        cursor: pointer;
    }
    label {
        display: block;
        font-weight: bold;
        margin: 0 0 3px;
        padding: 0;
        font-family: "Montserrat-Regular"!important;
        color: #000000!important;
    }

    .login-username, .login-password {
        margin-bottom: 30px;
        input {
            width: 100%;
        }
    }

    .login-submit input[type=submit] {
        border: none;
        font-family: "Montserrat-Regular";
        font-size: 14px;
        line-height: 18px;
        background: transparent;
        color: white;
        cursor: pointer;
        padding: 12px 14px!important;
        background-color: #0068B3!important;
        border-radius: 0;
        transition: all 0.3s!important;
        margin-top: 20px;
        &:hover {
            background-color: #002B4A!important;
        }
    }
}


.oxo-view.intra-form-admin {
    .block-inner {
        margin-bottom: 80px;
        @include mobile {
            margin-bottom: 60px;
        }
    }
    table {
        margin-top: 100px;
        margin-bottom: 60px;
        @include mobile {
            margin-top: 60px;
            margin-bottom: 40px;
        }
    }

    .export-view {
        border: none;
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        background: transparent;
        color: white;
        cursor: pointer;
        padding: 12px 14px!important;
        background-color: #0068B3!important;
        border-radius: 0;
        transition: all 0.3s!important;
        margin-top: 0px;
        text-decoration: none;
        &:hover {
            background-color: #002B4A!important;
        }
    }
}