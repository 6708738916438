.our-offer {
  position: relative;

  .intervention {
    opacity: 0;
  }

  .medium-banner {
    background-image: url(../img/team-background.png);
  }

  .e-cube {

    padding: 40px 0px 80px 0px !important;

    h4 {
      font-size: 14px;
      font-weight: 100;
      font-family: "Montserrat-Regular";
      line-height: 26px;

      strong {
        color: #0068B3;
        font-family: "Karla-Bold";
      }

    }

    .col-50 {
      &.text {
        @include tablet-landscape {
          width: 65%;
        }
      }

      &.cube {
        @include tablet-landscape {
          width: 35%;
        }
      }
    }
  }

  .e-cube, .conf-concu-soli {
    padding: 40px 0px;

    .col-50 {
      vertical-align: middle;
    }

    img {
      width: 220px;
      margin: 0 auto;
      display: block;
      padding: 40px 0px 0px 0px;

      @include tablet-landscape {
        padding: 0px 0px;
      }
    }

    p, ul, li {
      font-family: "Montserrat-Regular";
      line-height: 20px;

      @include tablet-landscape {
        padding: 0px 0px;
        line-height: 26px;
      }
    }

    ul {
      margin-left: 20px;
      margin-top: 20px;

      strong {
        font-family: "Karla-Bold";
        color: #0068B3;
      }

      li {
        margin: 10px 0px;
        list-style: initial;
      }
    }

    h2 {
      margin-bottom: 20px;
    }

  }

  .service-offer {
    padding: 30px 0px 0px 0px;

      h2 {
        text-align: center;
        font-family: "Karla-Bold";

        @include tablet-landscape {
          text-align: left;
        }

      }

      p {
        text-align: center;

        @include tablet-landscape {
          text-align: left;
        }
      }


    @include tablet-landscape {
      padding: 60px 0px;
    }

    .col-50 {
      vertical-align: middle;
    }

    p {
      font-family: "Montserrat-Regular";
      font-size: 14px;
      line-height: 18px;
      margin-top: 20px;
    }

    .image {
      text-align: center;

      @include tablet-landscape {
        text-align: right;
      }

      img {
        width: 100%;
        max-width: 496px;
        padding-left: 0px;
        box-sizing: border-box;
        margin-top: 40px;

        @include tablet-landscape {
          padding-left: 20px;
        }

      }
    }
  }

  .collabo {
    position: relative;
    padding: 30px 0px 60px;
    display: none;

    h2 {
      text-align: center;
    }

    p {
      text-align: center;
      font-family: "Montserrat-Regular";
      font-size: 14px;
      line-height: 18px;
      margin-top: 20px;
      max-width: 690px;
      margin: 0px auto 0;
      margin-bottom: 40px;
    }
  }

  .conf-concu-soli {
    text-align: center;
    margin-top: 20px;

    h2 {
      text-align: center;
      margin-bottom: 10px;

      @include tablet-landscape {
        margin-bottom: 30px;
      }
    }

    h3 {
      margin-bottom: 10px;
      margin-top: 20px;

      @include tablet-landscape {
        margin-top: 0px;
      }
    }

    img {
      width: 300px;

      @include tablet-landscape {
        width: 100%;
        max-width: 500px;
      }
    }

    p {
      text-align: center;

      @include tablet-landscape {
        text-align: left;
      }
    }

    .line {
      display: flex;
      flex-direction: column;
      margin: 20px 0px;

      @include tablet-landscape {
        flex-direction: row;
        margin: 50px 0px;
      }

      .col-50 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include tablet-landscape {
          align-items: initial;
        }

      }

      &.line-2 {

        .col-2 {
          padding-left: 0px;
          box-sizing: border-box;
        }

        @include tablet-landscape {

          .col-1 {

            p {
              box-sizing: border-box;
            }
          }

          .col-2 {
            padding-left: 110px;
            box-sizing: border-box;
          }
        }
      }

      &.line-1, &.line-3 {
        .col-1 {
          order: 2;

          p {
            padding-right: 0px;
            box-sizing: border-box;
          }
        }

        .col-2 {
          order: 1;
        }


        @include tablet-landscape {

          .col-1 {
            order: 1;

            p {
              padding-right: 110px;
              box-sizing: border-box;
            }
          }

          .col-2 {
            order: 2;
          }
        }

      }
    }

  }
}

.our-offer .conf-concu-soli {

  img {
    width: 100px;
    margin-bottom: 40px;
  }

  .col-33 {
    box-shadow: 0px 0px 27px -1px rgba(0,0,0,0);
    transform: scale(1);
    transition: 0.9s box-shadow ease, 0.6s transform ease-out;

    &:hover {
      box-shadow: 0px 0px 27px -1px rgba(0,0,0,0.2);
      transform: scale(1.05);
      transition: 0.9s box-shadow ease, 0.3s transform ease-out;
    }

    .inner {
      box-sizing: border-box;
      padding: 40px;
    }

    p {
      text-align: center;
    }
  }
}
