.btn {
  font-family: "Karla-Bold";
  padding: 8px 14px;
  background-color: #0068B3;
  text-transform: uppercase;
  color: white;
  text-decoration: none;
  font-size: 0;
  border: 1px solid #0068B3;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    height: 0px;
    width: calc(100% - 4px);
    bottom: -1px;
    background-color: white;
    left: 2px;
    transition: 0.6s height $ease-out-expo;
  }

  &:hover {
    &:before {
      height: 4px;
      transition: 0.6s height $ease-out-expo;
    }
  }

  @include tablet-landscape {
    padding: 15px 20px;
  }

  &.transparent {
    background-color: white;
    color: #0068B3;
    border: 1px solid #0068B3;

    &:before {
      background-color: #0068B3;
    }

    > span {
      .circle {
        background-image: url(../img/btn-icon-b.png);
      }
    }
  }

  > span {
    font-size: 14px;
    display: inline-block;
    vertical-align: middle;

    @include tablet-landscape {
      font-size: 16px;
    }

    .circle {
      position: relative;
      height: 30px;
      width: 38px;
      background-image: url(../img/btn-icon-w.png);
      background-size: contain;
      margin: 0px 0px 0px 13px;
    }
  }
}
