.board-of-director {
  .medium-banner {
    background-image: url(../img/team-background.png);
  }

  .the-board {
    padding: 40px 0px;

    @include tablet-landscape {
      padding: 80px 0px;
    }

  }


}
