.people {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;

  .people-face {
    position: relative;
    background-size: cover;
    background-position: center;
    background-image: url("../img/face1.png");
    border-radius: 50%;
    height: 160px;
    width: 160px;
    overflow: hidden;
    display: inline-block;
    border: 25px solid rgba(216,216,216, 1);

    &.blue {
      border: 25px solid #0068B3;
    }
  }

  .people-name {
    color: #0068B3;
    font-family: "Karla-Bold";
    text-transform: uppercase;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    display: block;
    margin-top: 20px;
    margin-bottom: 2px;
  }

  .people-status {
    font-family: "Montserrat-Regular";
    // font-size: 14px;
    // line-height: 18px;
  }

  .people-enterprise {
    font-family: "Montserrat-Regular";
    font-size: 14px;
    line-height: 21px;
    color: #00B7EB;
    margin-bottom: -20px;
  }

  .people-socials {
    position: relative;
    margin-top: 30px;

    img {
      margin: 0px 5px;
    }
  }
}
