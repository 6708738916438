.become-member {
  padding: 90px 0;
  background-color: #002B4A;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  text-align: center;

  .circle-decoration {
    position: absolute;
    top: 0px;
    border: 60px solid;
    border-radius: 50%;

    &.grey {
      border-color: #D0D4D4;
      height: 300px;
      width: 300px;
      top: 50%;
      left: -200px;
      transform: translate3d(0px, -370px, 0px);
      z-index: 1;
      display: none;

      @include tablet-landscape {
        display: block;
      }

    }

    &.blue-light {
      border-color: #00B7EB;
      height: 700px;
      width: 700px;
      right: -200px;
      transform: translateY(-80%);
      top: 50%;
      display: none;

      @include tablet-landscape {
        display: block;
      }

    }

    &.blue-medium {
      border-color: #0068B3;
      height: 500px;
      width: 500px;
      left: 0px;
      top: 50%;
      transform: translateY(-20%);
    }
  }
  
  p {
    font-family: "Montserrat-Light";
    color: white;
    display: block;
    position: relative;
    text-align: center;
    box-sizing: border-box;
    vertical-align: middle;
    z-index: 10;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 25px;

    @include tablet-landscape {
      font-size: 22px;
      line-height: 26px;
      margin-bottom: 0px;
      display: inline-block;
      padding-right: 40px;
    }

  }
}
