.about.network {
  position: relative;

  .founders .founder .founder-circle:before {
    display: none;
  }

  .about-network {
    padding: 30px 0px;
    text-align: center;

    @include tablet-landscape {
      padding: 60px 0px;
      text-align: left;
    }

    .image {
      text-align: right;
      display: none;

      @include tablet-landscape {
        display: inline-block;
      }

      img {
        width: 100%;
        max-width: 496px;
        padding-left: 20px;
        box-sizing: border-box;
      }
    }

    h3 {
      margin-top: 20px;
      margin-bottom: 10px;

      @include tablet-landscape {
        margin-top: 40px;
        margin-bottom: 20px;
      }

    }

    p {
      font-family: "Montserrat-Regular";
      // font-size: 14px;
      // line-height: 18px;
    }
  }

}

#markerLayer img {
  animation: pulse .5s infinite alternate;
  transform-origin: center;
}

#markerLayer div {
  z-index: 10000 !important;
}

#markerLayer + div {
  z-index: 20000 !important;
}

div[title='Marker1'], div[title='Marker2'], div[title='Marker3'], div[title='Marker4'], div[title='Marker5'], div[title='Marker6'], div[title='Marker7'] {
  z-index: 2000 !important;
}
