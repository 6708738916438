.summary {
  padding: 40px 0;
  text-align: center;

  @include tablet-landscape {
    padding: 80px 0;
  }

  .btn {
    margin-top: 60px;
    margin-left: 20px;
    margin-right: 20px;
  }

  h2 {
    // font-family: "Karla-Regular";
    // font-size: 30px;
    text-align: center;
  }

  p {
    font-family: "Montserrat-Regular";
    text-align: center;
    max-width: 713px;
    margin: 0 auto;
    margin-top: 10px;
    font-size: 12px;
    line-height: 16px;

    @include tablet-portrait {
      width: 80%;
    }

    @include tablet-landscape {
      font-size: 14px;
      line-height: 18px;
    }

  }

  .stat-line {
    width: 100%;
    font-size: 0;
    margin-top: 30px;

    .stat {
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      width: 100%;
      margin-bottom: 30px;

      @include tablet-portrait {
        width: 50%;
      }

      @include tablet-landscape {
        width: 25%;
        margin-bottom: 0px;
      }

      .top {
        font-family: "Roboto-Thin";
        font-size: 70px;
        color: #00B7EB;

        @include tablet-landscape {
          min-height: 90px;
        }
      }

      .bottom {
        font-family: "Karla-Bold";
        color: #0068B3;
        font-size: 16px;
        text-transform: uppercase;
      }

    }

  }

}
