.home {

  .hero-banner {
    position: relative;
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;

    .title {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;

      h1 {
        font-family: "Karla-Bold";
        color: white;
        text-align: center;
      }

      p {
        font-family: "Karla-Bold";
        color: white;
        text-align: center;
        margin-top: 10px;
        font-size: 12px;

        @include tablet-portrait {
          font-size: 22px;
        }

        @include tablet-landscape {
          font-size: 25px;
        }

      }
    }
  }
}
