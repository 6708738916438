.intervention {
  position: relative;
  background-color: white;
  padding: 60px 0px;

  h2 {
    font-family: "Karla-Regular";
    // font-size: 30px;
    // line-height: 36px;
    text-align: center;

    @include smobile {
      font-size: 19px;
      line-height: 26px;
    }
  }

  .accordions {
    margin-top: 40px;

    .accordion {
      &.open {
        .bottom {
          max-height: 1000px;
          transition: 0.6s max-height ease-out;
        }

        .top .arrow img {
          transform: rotate(0deg);
          transition: transform 0.3s ease-out;
        }
      }

      .top {
        font-size: 0;
        border-top: 1px solid #D8D8D8;
        cursor: pointer;
        padding: 0px 0px;

        @include tablet-portrait {
          padding: 10px 0px;
        }

        .icon {
          display: inline-block;
          vertical-align: middle;
          width: 70px;

          @include tablet-portrait {
            width: 100px;
          }

          img {
            width: 100%;
          }

        }

        .title {
          display: inline-block;
          vertical-align: middle;
          width: calc(100% - 70px - 30px);
          padding-right: 10px;
          box-sizing: border-box;
          padding-left: 10px;

          @include tablet-portrait {
            width: calc(100% - 100px - 40px);
            padding-right: 30px;
            padding-left: 50px;
          }

          h3 {
            font-family: "Montserrat-Light";
            box-sizing: border-box;
            padding: 20px 0px;
          }
        }

        .arrow {
          width: 30px;
          display: inline-block;
          vertical-align: middle;
          text-align: center;

          @include tablet-portrait {
            width: 40px;
          }

          img {
            transform: rotate(180deg);
            transition: transform 0.2s ease-out;
          }
        }
      }

      .bottom {
        display: block;
        vertical-align: top;
        max-height: 0px;
        overflow: hidden;
        transition: 0.6s max-height ease-out;

        @include tablet-portrait {
          padding-left: calc(100px + 50px);
        }

        h4 {
          color: #0068B3;
          font-size: 16px;
          line-height: 24px;
          font-family: "Karla-Bold";
          text-transform: uppercase;
          margin-bottom: 10px;
        }

        p {
          font-family: "Montserrat-Regular";
          // font-size: 14px;
          // line-height: 18px;
          margin-bottom: 40px;
        }

      }

    }
  }
}
